import styles from "./App.module.css";

function App() {
  return (
    <div className={styles.app}>
      <h1 className={styles.henlo}>Henlo!</h1>
    </div>
  );
}

export default App;
